require('./bootstrap');

function productAutocomplete(e){
   let item = e.target
    axios.get('/products/autocomplete', {
        params: {
            search: item.value
        }
    }).then(response =>{
        let products = response.data;
        let dropdown = item.parentNode.querySelector('.dropdown-menu')
        dropdown.innerHTML = '';
        products.forEach(product => {
            let li = document.createElement('li')
            li.textContent = product.name;
            if(product.serial_number)
                li.textContent += ' - ' + product.serial_number;
            li.dataset.id = product.id;
            li.classList.add('dropdown-item');
            li.classList.add('product-item');
            dropdown.appendChild(li)
        })

        if(item.value && products.length)
            dropdown.classList.add('show')
    })
}

if(document.querySelector('#toggle-password'))
document.querySelector('#toggle-password').addEventListener('click', function () {
    this.classList.toggle('show')

    let input = document.querySelector('#new_password') ? document.querySelector('#new_password') : document.querySelector('#password');

    if(input.getAttribute('type') === 'password')
        input.setAttribute('type', 'text')
    else
        input.setAttribute('type', 'password')
})

document.querySelectorAll('input[name="product"]').forEach(item => {
    item.addEventListener('input', productAutocomplete)
    item.addEventListener('focus', productAutocomplete)
})

document.addEventListener('click', function (e) {
    if(e.target.classList.contains('product-item')) {
        let item = e.target;
        document.querySelector('input[name=product_id]').value = item.dataset.id

        document.querySelector('#name').value = item.innerText;

        axios.get('/products/serials', {
            params: {
                id: item.dataset.id
            }
        }).then(resp => {
            let items = resp.data.items;
            let select = document.querySelector('#serial_number');
            select.innerHTML = '';
            items.forEach(item => {
                if(item.serial_number){
                    let elem = document.createElement('option')
                    elem.innerHTML = item.serial_number;
                    elem.value = item.id;
                    select.appendChild(elem)
                }
            })

            if (items.some(item => item.serial_number === null)){
                let elem = document.createElement('option')
                elem.innerHTML = 'N/A';
                elem.value = 0;
                select.prepend(elem)
                select.value = 0;
            }

            if(!select.options.length) {
                let html = '<div class="alert alert-danger" id="no-items-error">כל יחידות הציוד הזה כבר הושאלו</div>';
                let template = document.createElement('template');
                template.innerHTML = html;
                document.querySelector('#form').prepend(template.content.firstChild)
            } else {
                if(document.querySelector('#no-items-error'))
                    document.querySelector('#no-items-error').remove();
            }

            if(resp.data.image){
                let preview = document.getElementById('preview');
                preview.src = resp.data.image;
                preview.onload = function() {
                    URL.revokeObjectURL(preview.src)
                    preview.style.display = 'block';
                }
            } else preview.style.display = 'none';

            axios.get('/products/instructions', {
                params: {
                    id: item.dataset.id
                }
            }).then(resp => {
                if(resp.data.instruction){
                    document.querySelector('#instructions').value = resp.data.instruction;
                    document.querySelector('#instructions').parentNode.style.display = 'block';
                } else {
                    document.querySelector('#instructions').parentNode.style.display = 'none';
                }
            });
        });
        item.parentNode.classList.remove('show')
    }

    if(e.target.classList.contains('delete-item')){
        e.target.closest('.item').remove()

        let id = e.target.dataset.id;

        if(id)
            axios.get('/products/items/delete/'+id, {
                params: {
                    id
                }
            }).then(resp => console.log(resp))

    }

   if(document.querySelector('#name + .dropdown-menu') && (e.target !== document.querySelector('#name + .dropdown-menu') ||  document.querySelector('#name + .dropdown-menu').contains(e.target))){
       document.querySelector('#name + .dropdown-menu').classList.remove('show')
   }

   if(e.target !== document.querySelector('#toggle-sidebar')  && (e.target !==  document.querySelector('.sidebar') || !document.querySelector('.sidebar').contains(e.target))){
       document.querySelector('.sidebar').classList.remove('show')
   }

})

document.addEventListener('input', function (e) {
    if(e.target.getAttribute('name') !== 'product') return;
    productAutocomplete(e);
})

if(document.querySelector('#image'))
    document.querySelector('#image').addEventListener('change', event => {
        let preview = document.getElementById('preview');
        preview.src = URL.createObjectURL(event.target.files[0]);
        preview.onload = function() {
            URL.revokeObjectURL(preview.src)
            preview.style.display = 'block';
        }
    })

if(document.querySelector('#is_returned'))
document.querySelector('#is_returned').addEventListener('change', function () {
    if(this.checked)
        document.querySelector('#date_ended').value = new Date().toISOString().slice(0,10);
})

let counter = 1;

if(document.querySelector('#add-item'))
document.querySelector('#add-item').addEventListener('click', function (e) {
    e.preventDefault()
    let html = '<div class="d-flex gap-3 flex-wrap mb-3 item" data-index="'+counter+'">\n' +
        ' <input type="hidden" name="items['+counter+'][id]" value="0">' +
        '                                         <div class="form-group ">\n' +
        '                                            <label for="availability" class="form-label">זמינות</label>\n' +
        '                                            <select name="items['+counter+'][availability]" id="availability"  class="form-control  returned" disabled>\n' +
        '                                                <option value="yes" selected>כן</option>\n' +
        '                                                <option value="no">לֹא</option>\n' +
        '                                            </select>\n' +
        '                                        </div>' +
        '                                        <div class="form-group flex-grow-1">\n' +
        '                                            <label for="serial_number" class="form-label">מס׳ סידורי</label>\n' +
        '                                            <input type="text" name="items['+counter+'][serial_number]" id="serial_number" class="form-control" placeholder="N/A">\n' +
        '                                        </div>\n                         ' +
        '                                        <div class="form-group flex-grow-1">\n' +
        '                                            <label for="condition" class="form-label">מצב</label>\n' +
        '                                            <select name="items['+counter+'][condition]" id="condition" class="form-select" disabled>\n' +
        '                                                <option value="New">ניתן לשירות</option>\n' +
        '                                                <option value="Damaged">פגום</option>\n' +
        '                                            </select>\n' +
        '                                        </div>\n' +
        '                                        <div class="flex-grow-1">\n' +
        '                                            <label for="defect" class="form-label">תיאור הפגם</label>\n' +
        '                                            <textarea name="items['+counter+'][defect]" id="defect" rows="1" class="form-control" disabled style="height: 38px"></textarea>\n' +
        '                                        </div>\n' +
        '                                               <div class="d-flex align-items-center gap-3 pt-32">\n' +
        '                                                    <a href="#" class="btn btn-primary px-3 disabled">\n' +
        '                                                        <img src="/img/history.svg" alt="history">\n' +
        '                                                    </a>\n' +
        '                                                    <button type="button" class="btn btn-danger px-3 delete-item">\n' +
        '                                                        <img src="/img/trash.svg" alt="delete">\n' +
        '                                                    </button>\n' +
        '                                                </div>' +
        '                                    </div>';
    let template = document.createElement('template');
    template.innerHTML = html;
    document.querySelector('#items').appendChild(template.content.firstChild)
    counter++;
})

document.addEventListener('change', function (e) {
    let parent = e.target.parentNode.parentNode;

    if(e.target.name.indexOf('[condition]') !== -1) {
        if(e.target.value === 'Damaged')
            parent.querySelector('#defect').removeAttribute('disabled')
        else{
            parent.querySelector('#defect').setAttribute('disabled', 'disabled')
            parent.querySelector('#defect').value = '';
        }
        return;
    }

    if(e.target.name.indexOf('[serial_number]') !== -1){
        if(e.target.value != '')
           parent.querySelector('#condition').removeAttribute('disabled')
        else
            parent.querySelector('#condition').setAttribute('disabled', 'disabled')

        return;
    }

})

if(document.querySelector('#serial_number'))
document.querySelector('#serial_number').addEventListener('change', function () {
    if(this.value === '0') {
        document.querySelector('#defect').parentNode.style.display = 'none';
        return;
    }

    axios.get('/products/defect', {
        params: {
            id: this.value
        }
    }).then(resp => {
        let data = resp.data;

        if(data.defect){
            document.querySelector('#defect').value = data.defect;
            document.querySelector('#defect').parentNode.style.display = 'block';
        } else {
            document.querySelector('#defect').parentNode.style.display = 'none';
        }
    })
})

if(document.querySelector('#status-filter'))
    document.querySelector('#status-filter').addEventListener('change', function () {
        const urlParams = new URLSearchParams(window.location.search);
        urlParams.set('status', this.value);
        window.location.search = urlParams;
    })

if(document.querySelector('#filter_date_from')){
    const urlParams = new URLSearchParams(window.location.search);
    let date_from =  document.querySelector('#filter_date_from');
    let date_to =  document.querySelector('#filter_date_to');
    date_from.addEventListener('change', function () {
        if(this.value && date_to.value){
            urlParams.set('date_from', this.value);
            urlParams.set('date_to', date_to.value);
            window.location.search = urlParams;
        }
    })
    date_to.addEventListener('change', function () {
        if(this.value && date_from.value){
            urlParams.set('date_from', date_from.value);
            urlParams.set('date_to', date_to.value);
            window.location.search = urlParams;
        }
    })
}

if(document.querySelector('#overdue'))
    document.querySelector('#overdue').addEventListener('change', function () {
        const urlParams = new URLSearchParams(window.location.search);
        if(this.checked)
            urlParams.set('status', 'overdue');
        else
            urlParams.set('status', '');
        window.location.search = urlParams;
    })

document.querySelector('#toggle-sidebar').addEventListener('click', function () {
    document.querySelector('.sidebar').classList.add('show')
})

if(document.querySelector('input[name=search]'))
document.querySelector('input[name=search]').addEventListener('change', function () {
    const urlParams = new URLSearchParams(window.location.search);
    if(this.value === '')
        urlParams.set('search', '');
    window.location.search = urlParams;
})

if(document.querySelector('input#deposit'))
    document.querySelector('input#deposit').addEventListener('keydown', function (e) {
      if((e.keyCode < 48 || e.keyCode > 57)
          && (e.keyCode !== 8
          && e.keyCode !== 190 && !e.shiftKey)){
          e.preventDefault()
          return false;
      }
        return true;
    })

if(document.querySelector('.confirm-remove')){
    document.querySelectorAll('.confirm-remove').forEach(item => {
        item.addEventListener('click', function (e) {
            e.preventDefault();
            document.querySelector('#confirm').setAttribute('href' ,item.getAttribute('href'))
        })
    })
}
